import styled from "styled-components";
import {
  BUTTON_FONT,
  DIVIDER,
  GREY_BACKGROUND,
  MAIN,
  WHITE,
} from "../../styles/colors";
import { Button } from "antd";

export const Container = styled.div<{
  containerWidth?: number;
  pageNumber: number;
}>`
  width: ${({ containerWidth }) => containerWidth || "1400px"};
  background-color: ${GREY_BACKGROUND};

  .document-container {
    display: flex;
    padding: 35px 35px 0;
  }

  .display-page {
    margin-left: 35px;
  }

  .page-list-item {
    margin-bottom: 10px;
  }

  .page-list-item:nth-of-type(${({ pageNumber }) => pageNumber}) {
    canvas {
      border: 2px solid ${MAIN};
    }
  }
`;

export const PreviewerListContainer = styled.div`
  width: 50px;
  text-align: center;
`;

export const StyledButton = styled(Button)`
  border: none;
  border-bottom: 1px solid ${DIVIDER};
  border-radius: 0;
  font-size: 16px;
  color: ${BUTTON_FONT};
  background-color: ${WHITE};
  padding: 15px;

  &:nth-of-type(4) {
    border: none;
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 50px;
  background-color: ${WHITE};
`;
