import React from "react";
import styled from "styled-components";
import { Typography } from "antd";
import { MAIN, WHITE } from "../../../styles/colors";

const Container = styled.div`
  width: max-content;
  margin: 10vh auto 0;
`;

const SubText = styled(Typography.Title)`
  &&& {
    text-align: center;
    margin: 35px 0 0;
    color: ${MAIN};
    font-weight: bold;
    font-size: 30px;
  }
`;

const PriceText = styled(Typography.Title)`
  &&& {
    text-align: center;
    margin: 5px 0 0;
    color: ${WHITE};
    font-weight: bold;
    font-size: 80px;
  }
`;

const DisplayBrand = () => {
  return (
    <Container>
      <img src="/assets/doctors-sign-in.svg" alt="doctors" />
      <SubText level={4}>Saving your practice on average</SubText>
      <PriceText level={2}>
        <sup>$</sup>141,000
      </PriceText>
    </Container>
  );
};

export default DisplayBrand;
