import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorBoundary from "./pages/ErrorBoundary";
import "./styles/global";

import { AuthProvider } from "./contexts/authContext";

ReactDOM.render(
  <ErrorBoundary>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
