import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "./pages/SignIn";
import Dashboard from "./pages/Dashboard";
import AuthIsSignedIn from "./routes/AuthIsSignedIn";
import { PDF_PREVIEWER, SIGN_IN } from "./routes/routes-constant";
import PDFPreviewer from "./components/PDFPreviewer";

import "./styles/antd.css";

const App = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <AuthIsSignedIn>
            <Dashboard />
          </AuthIsSignedIn>
        }
      />
      <Route path={SIGN_IN} element={<SignIn />} />
      <Route path={PDF_PREVIEWER} element={<PDFPreviewer pdfURL="" />} />
    </Routes>
  );
};

export default App;
