import React, { FC, useContext, useState } from "react";
import { Form, Input, Button, Alert, Typography, Row, Col } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext, IAuth } from "../../../contexts/authContext";
import { DASHBOARD } from "../../../routes/routes-constant";
import { ILocationState } from "../../../types/ILocationState";
import { STATUS } from "../../../types/Status";
import styled from "styled-components";
import { BUTTON_FONT, ERROR_FONT, MAIN } from "../../../styles/colors";
import { CloseCircleOutlined } from "@ant-design/icons";

interface IUserAuthInfo {
  userName: string;
  password: string;
}

const SignInContainer = styled.div`
  width: max-content;
  height: max-content;
  margin: 15vh auto 0;
  & .ant-form {
    width: 360px;
    background-color: white;
    border-radius: 6px;
    padding: 30px;
  }
`;

const Logo = styled.div`
  margin: 0 auto 70px;
  width: max-content;
`;

const FormItemContainer = styled(Form.Item)`
  margin-bottom: 20px;

  &:nth-of-type(3) {
    margin-bottom: 6px;
  }

  & .ant-form-item-label {
    padding-bottom: 4px;

    & > label {
      font-weight: bold;
      font-size: 12px;
    }
  }
`;

const SignButton = styled(Button)`
  background-color: ${MAIN};
  border-radius: 3px;
`;

const StyledLink = styled(Link)`
  color: ${BUTTON_FONT};
  opacity: 0.5;
  font-weight: bold;
  font-size: 12px;
`;

const Error = styled.div`
  color: ${ERROR_FONT};
  margin: 10px auto 0;

  & > span {
    margin-right: 5px;
  }
`;

const Login: FC = () => {
  const { signInWithEmail }: IAuth = useContext(AuthContext);
  const [loginStatus, setLoginStatus] = useState<STATUS>(STATUS.DEFAULT);
  const navigate = useNavigate();
  const state = useLocation().state as ILocationState;

  const handleLogin = async (value: IUserAuthInfo) => {
    try {
      const { userName, password } = value;
      setLoginStatus(STATUS.LOADING);
      await signInWithEmail(userName, password);
      setLoginStatus(STATUS.SUCCESS);
      navigate(state?.path || DASHBOARD);
    } catch (error) {
      setLoginStatus(STATUS.FAILURE);
    }
  };

  return (
    <SignInContainer>
      <Logo>
        <img src="/assets/scnr-logo.svg" alt="logo" />
      </Logo>
      <Form name="signIn" onFinish={handleLogin} layout="vertical">
        <Typography.Title
          level={2}
          style={{ marginBottom: 20, textAlign: "center" }}
        >
          Login
        </Typography.Title>
        <FormItemContainer
          label="EMAIL"
          name="userName"
          required={false}
          rules={[
            {
              required: true,
              message: "Please input your user name",
            },
          ]}
        >
          <Input placeholder="user name" size="large" />
        </FormItemContainer>

        <FormItemContainer
          name="password"
          required={false}
          label="PASSWORD"
          rules={[
            { required: true, message: "The password can not be empty." },
          ]}
        >
          <Input type="password" placeholder="Password" size="large" />
        </FormItemContainer>

        <FormItemContainer>
          <SignButton
            type="primary"
            htmlType="submit"
            size="large"
            block
            loading={loginStatus === STATUS.LOADING}
          >
            Sign In
          </SignButton>
        </FormItemContainer>

        <Row justify="space-between">
          <Col>
            <StyledLink to={"/"}>FORGOT PASSWORD?</StyledLink>
          </Col>

          <Col>
            <StyledLink to={"/"}>SIGN UP</StyledLink>
          </Col>
        </Row>

        {loginStatus === STATUS.FAILURE && (
          <Error>
            <CloseCircleOutlined twoToneColor={ERROR_FONT} />
            Login failed, please check if your username and password are
            correct.
          </Error>
        )}
      </Form>
    </SignInContainer>
  );
};

export default Login;
