import React, { FC, useContext, useState } from "react";
import { Button, Typography } from "antd";
import axiosApiInstance from "../../libs/AxiosService";
import { AuthContext } from "../../contexts/authContext";

const Dashboard: FC = () => {
  const [data, setData] = useState();
  const { signOut } = useContext(AuthContext);

  const fetchData = async () => {
    const { data } = await axiosApiInstance.get(
      `${process.env.REACT_APP_PMS_BFF_BASE_ENDPOINT}`
    );
    setData(data);
  };

  const handleClick = () => {
    signOut();
  };

  const handleSendRequest = () => {
    fetchData();
  };

  return (
    <>
      <Typography.Title style={{ textAlign: "center" }}>
        {" "}
        Hello world
      </Typography.Title>
      <Typography.Title level={5} style={{ textAlign: "center" }}>
        data from bff {data}
      </Typography.Title>

      <Button onClick={handleClick}>Logout</Button>
      <Button onClick={handleSendRequest}>Send Request</Button>
    </>
  );
};

export default Dashboard;
