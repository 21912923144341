import React from "react";
import { Typography } from "antd";
import styled from "styled-components";

import { Logo, MainSection, TextSection } from "./styles";

const Container = styled.section`
  padding: 45px 10px;
  text-align: center;
`;

const { Title, Text } = Typography;

const Error = ({ message }: { message?: string }) => (
  <Container>
    <header>
      <Logo
        alt="logo"
        width="15%"
        height="68px"
        src="/assets/telecare-logo.png"
      />
    </header>
    <MainSection>
      <Title level={2} style={{ marginBottom: "50px" }}>
        Oops! Something went wrong...
      </Title>
      {message && <Text>{message}</Text>}
    </MainSection>
    <TextSection style={{ marginBottom: "50px" }}>
      <img src="/assets/doctors.svg" alt="doctors" />
      <Title level={4} style={{ fontWeight: "normal" }}>
        Please contact Telecare at (03 9052 4872) or via live chat.
      </Title>
    </TextSection>
  </Container>
);

export default Error;
