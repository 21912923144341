export const setAuthTokenInLocalStorage = (session: any): any => {
  window.localStorage.setItem("accessToken", `${session.accessToken.jwtToken}`);
  window.localStorage.setItem("refreshToken", `${session.refreshToken.token}`);
};

export const getAuthTokenFromLocalStorage = (): any => {
  return {
    accessToken: window.localStorage.getItem("accessToken"),
    refreshToken: window.localStorage.getItem("refreshToken"),
  };
};

export const resetAuthInfoInLocalStorage = (): any => {
  window.localStorage.removeItem("accessToken");
  window.localStorage.removeItem("refreshToken");
};
