import React, { FC } from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import SignInForm from "./Form";
import DisplayBrand from "./DisplayBrand";
import { BUTTON_FONT, MAIN } from "../../styles/colors";

const Container = styled(Row)`
  width: 100%;
  height: 100vh;
`;

const FormContainer = styled(Col)`
  background-color: ${MAIN};
`;

const DisplayBrandContainer = styled(Col)`
  background-color: ${BUTTON_FONT};
`;

const SignIn: FC = () => {
  return (
    <Container>
      <FormContainer span={11}>
        <SignInForm />
      </FormContainer>

      <DisplayBrandContainer span={13}>
        <DisplayBrand />
      </DisplayBrandContainer>
    </Container>
  );
};

export default SignIn;
