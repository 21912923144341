import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  FullscreenOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  ButtonGroup,
  Container,
  PreviewerListContainer,
  StyledButton,
} from "./styles";
import Error from "../Error";
import { STATUS } from "../../types/Status";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PreviewerProps {
  containerWidth?: number;
  pdfURL: string;
}

interface Result {
  status: STATUS;
  message?: string;
}

const Loading: FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <h1>Loading...</h1>
    </div>
  );
};

const DOCUMENT_ERROR = "Ops, failed to load PDF, please try again.";

const PDFPreviewer: FC<PreviewerProps> = ({ containerWidth, pdfURL }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState<number>(1);
  const [documentResult, setDocumentResult] = useState<Result>({
    status: STATUS.DEFAULT,
  });
  const [pagesResult, setPagesResult] = useState<Result>({
    status: STATUS.DEFAULT,
  });
  const [rotate, setRotate] = useState(0);
  const pageRef = useRef<null | HTMLCanvasElement>(null);
  const pageListArray = useMemo(
    () => Array.from({ length: numPages || 0 }, (_, i) => i + 1),
    [numPages]
  );

  const onDocumentLoadSuccess = useCallback(
    (pdf: any) => {
      setNumPages(pdf.numPages);
    },
    [setNumPages]
  );

  const onReducePageScale = useCallback(() => {
    if (pageScale < 0.2) {
      return;
    }

    const newPageScale = Number(Number(pageScale - 0.1).toFixed(1));

    setPageScale(newPageScale);
  }, [pageScale, setPageScale]);

  const onRequestFullScreen = useCallback(() => {
    const element = pageRef.current;
    if (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
        return;
      }
      if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
        return;
      }
      if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
        return;
      }
    }
  }, [pageRef]);

  const onLoadDocumentError = useCallback(() => {
    setDocumentResult({ status: STATUS.FAILURE, message: DOCUMENT_ERROR });
  }, [setDocumentResult]);

  const onLoadPageError = useCallback(() => {
    setPagesResult({ status: STATUS.FAILURE, message: DOCUMENT_ERROR });
  }, [setPagesResult]);

  if (documentResult.status === STATUS.FAILURE) {
    return <Error message={documentResult.message} />;
  }

  return (
    <Container pageNumber={pageNumber} containerWidth={containerWidth}>
      <Document
        file={pdfURL}
        loading={<Loading />}
        error={<Error message={DOCUMENT_ERROR} />}
        onLoadSuccess={onDocumentLoadSuccess}
        className="document-container"
        onLoadError={onLoadDocumentError}
        onSourceError={onLoadDocumentError}
      >
        {pagesResult.status !== STATUS.FAILURE && (
          <PreviewerListContainer>
            PAGES
            <h3>
              {pageNumber} / {numPages}
            </h3>
            {pageListArray.map((i) => (
              <Page
                onClick={() => setPageNumber(i)}
                width={50}
                key={i}
                pageNumber={i}
                className="page-list-item"
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            ))}
            <ButtonGroup>
              <StyledButton
                icon={<ReloadOutlined />}
                onClick={() => {
                  setRotate((rotate + 90) % 360);
                }}
              />
              <StyledButton
                icon={<PlusOutlined />}
                onClick={() => setPageScale(pageScale + 0.1)}
              />
              <StyledButton
                icon={<MinusOutlined />}
                disabled={pageScale < 0.2}
                onClick={onReducePageScale}
              />
              <StyledButton
                icon={<FullscreenOutlined />}
                onClick={onRequestFullScreen}
              />
            </ButtonGroup>
          </PreviewerListContainer>
        )}
        <Page
          pageNumber={pageNumber}
          scale={pageScale}
          width={1262}
          rotate={rotate}
          canvasRef={pageRef}
          className="display-page"
          onRenderError={onLoadPageError}
          onLoadError={onLoadPageError}
        />
      </Document>
    </Container>
  );
};

export default PDFPreviewer;
